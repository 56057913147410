import { Link } from "react-router-dom";
import logo from "../media/La_Floraison_Outline.svg";
import "./home.css";

export const Home = () => (
  <div className="HomemadeHome">
    <div className="HomemadeWrapper">
      <img height="400" width="400" src={logo} alt="La Floraison logo" />
      <h1>Homemade goods,</h1>
      <h1>coming soon!</h1>
      <a href="https://bakery.lafloraison.store">Visit our bakery now!</a>
    </div>
  </div>
);
