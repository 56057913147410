import logo from "./media/La_Floraison_Color.png";
import "./LandingPage.css";

export const LandingPage = () => (
  <div className="LandingPage">
    <header className="LandingPageHeader">
      <img src={logo} alt="La Floraison logo" />
    </header>
    <main className="LandingPageMain">
      <div className="LandingPageBakery">
        <div className="LandingPageTextBox">
          <a href="https://bakery.lafloraison.store">Bakery</a>
        </div>
      </div>
      <div className="LandingPageHomemade">
        <div className="LandingPageTextBox">
          <a href="https://homemade.lafloraison.store">Homemade Goods</a>
        </div>
      </div>
    </main>
    <footer className="LandingPageFooter">
      By <b>Ashley Muhm</b>
    </footer>
  </div>
);
