import React, { useState, useEffect } from "react";

import {
  AuthCheck,
  useFunctions,
  useAuth,
  useUser,
  useFirestore,
  SuspenseWithPerf,
} from "reactfire";
import firebase from "firebase/app";

import logo from "../media/La_Floraison_Color.png";
import "./Admin.css";

const SignIn = () => {
  const auth = useAuth();
  const signIn = async () => {
    await auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  };

  return (
    <div>
      <button className="AdminInvoiceButton" onClick={signIn}>
        Sign In
      </button>
    </div>
  );
};

const SignOut = (props: { user: firebase.User }) => {
  const auth = useAuth();

  return (
    props.user && (
      <button className="AdminInvoiceButton" onClick={() => auth.signOut()}>
        Sign Out
      </button>
    )
  );
};

export const Home = () => {
  const { data: user } = useUser();
  const functions = useFunctions();
  const [message, setMessage] = useState(<></>);
  const [customOrders, setCustomOrders]: [
    (
      | firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
      | undefined
    ),
    React.Dispatch<
      React.SetStateAction<
        | firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
        | undefined
      >
    >
  ] = useState();
  const db = useFirestore();

  useEffect(() => {
    if (!customOrders) {
      db.collection("customOrder")
        .where("hasInvoice", "==", false)
        .get()
        .then((res) => {
          setCustomOrders(res);
        })
        .catch((err) => console.log(err));
    }
  }, [customOrders, db]);

  return (
    <SuspenseWithPerf traceId={"firebase-user-wait"} fallback={<></>}>
      <div className="AdminInvoice">
        <header className="AdminInvoiceHeader">
          <a href="https://lafloraison.store">
            <img src={logo} alt="La Floraison logo" />
          </a>
          <div className="AdminInvoiceNav">
            <SignOut user={user} />
          </div>
        </header>
        <main className="AdminInvoiceMain">
          <AuthCheck fallback={<SignIn />} requiredClaims={{ admin: true }}>
            <div className="AdminInvoiceMainHeader">
              <h1>Create an invoice</h1>
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Category</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {customOrders &&
                    customOrders.docs.map((order) => (
                      <tr key={order.id}>
                        <td>{order.id}</td>
                        <td>{order.data().name}</td>
                        <td>{order.data().description}</td>
                        <td>{order.data().phone}</td>
                        <td>{order.data().email}</td>
                        <td>
                          {order.data().categories &&
                            order.data().categories.join(", ")}
                        </td>
                        <td>{order.data().createdAt.toDate().toString()}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <form
              id="InvoiceForm"
              onSubmit={(e: React.FormEvent<EventTarget>) => {
                e.preventDefault();
                setMessage(
                  <div className="AdminInvoiceComplete">
                    Processing... Please wait.
                  </div>
                );

                const formInput = e.target as HTMLFormElement;

                const data = {
                  customOrderId: (formInput[0] as HTMLInputElement).value.trim(),
                  cost: (formInput[1] as HTMLInputElement).value,
                  dueDate: (formInput[2] as HTMLInputElement).value,
                };

                functions
                  .httpsCallable("createInvoice")(data)
                  .then((res) => {
                    setMessage(
                      <div className="AdminInvoiceComplete">
                        The invoice has been created! The customer will receive
                        an email with a link to pay for it.
                      </div>
                    );

                    (formInput[0] as HTMLInputElement).value = "";
                    (formInput[1] as HTMLInputElement).value = "";
                    (formInput[2] as HTMLInputElement).value = "";
                  })
                  .catch((err) => {
                    setMessage(
                      <div className="AdminInvoiceComplete">
                        There was a problem creating the invoice. Tell Morgan,
                        and I'll fix it.
                      </div>
                    );
                  });
              }}
            >
              <label>
                Custom Order ID
                <input id="customOrderId" type="text" />
              </label>
              <label>
                Cost (in cents)
                <input id="cost" type="number" />
              </label>
              <label>
                Due date
                <input id="date" type="date" />
              </label>
              <button className="btn btn-success" type="submit">
                Create Invoice
              </button>
            </form>
            {message !== <></> && message}
          </AuthCheck>
        </main>
        <footer className="AdminInvoiceFooter">
          By <b>Ashley Muhm</b>
        </footer>
      </div>
    </SuspenseWithPerf>
  );
};
