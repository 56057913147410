import { Switch, Route } from 'react-router-dom';
import { Home } from './home';
import { Invoice } from './invoice';
import { CustomOrder } from './customOrder';

export const Routes = () =>
    <Switch>
      <Route path="/invoice/:invoiceId" component={Invoice} />
      <Route path="/customOrder" component={CustomOrder} />
      <Route path="/" component={Home} />
    </Switch>
