import { Link } from "react-router-dom";

import logo from "../media/La_Floraison_Color.png";
import bakeryMuffinImg from "../media/bakeryhomemuffin.jpg";
import cakeImg from "../media/cake.jpg";
import "./Bakery.css";

export const Home = () => (
  <div className="Bakery">
    <header className="BakeryHeader">
      <a href="https://lafloraison.store">
        <img src={logo} alt="La Floraison logo" />
      </a>
      <nav className="BakeryNav">
        <ul>
          <li>
            <Link to="/customOrder">Custom Order</Link>
          </li>
          {false && (
            <li>
              <Link to="#">Pay Invoices</Link>
            </li>
          )}
          {false && (
            <li>
              <Link to="#">Contact</Link>
            </li>
          )}
        </ul>
      </nav>
    </header>
    <main className="BakeryMain">
      <img className="BakeryMainImgBox" src={bakeryMuffinImg} />
      <div className="BakeryMainCustomOrderTextBoxWrapper">
        <div className="BakeryMainCustomOrderTextBox">
          <p>Hello, there!</p>
          <p>We are a custom order bakery based in Lexington, KY.</p>
        </div>
        <div className="BakeryMainImgBox">
          <img src={cakeImg} />
        </div>
        <div className="BakeryMainCustomOrderTextBox">
          <p>Thank you for visiting!</p>
        </div>
      </div>
    </main>
    <footer className="BakeryFooter">
      By <b>Ashley Muhm</b>
    </footer>
  </div>
);
