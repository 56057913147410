import Stripe from "@stripe/stripe-js";
import { useParams, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useFunctions } from "reactfire";

import logo from "../media/La_Floraison_Color.png";
import "./BakeryInvoice.css";

type PathParams = {
  invoiceId: string;
};

export const Invoice = () => {
  const { invoiceId } = useParams() as PathParams;
  const stripe = useStripe();
  const elements = useElements();
  const functions = useFunctions();

  const [paymentIntent, setPaymentIntent]: [
    Stripe.PaymentIntent | undefined,
    React.Dispatch<React.SetStateAction<Stripe.PaymentIntent | undefined>>
  ] = useState();
  const [message, setMessage] = useState("");

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Create a payment intent on the server
    if (!paymentIntent) {
      functions
        .httpsCallable("payInvoice")({ invoiceId: invoiceId })
        .then((res: { data: Stripe.PaymentIntent }) => {
          const pi = res.data as Stripe.PaymentIntent;
          console.log(pi);
          setPaymentIntent(pi);
        })
        .catch((err) => {
          console.log(err);
          setMessage("Could not retrieve invoice information at this time.");
        });
    }
  }, [invoiceId, functions, paymentIntent]);

  // Handle the submission of card details
  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setMessage("Payment processing... Please wait.");
    let cardElement = null;

    if (elements) cardElement = elements.getElement(CardElement);
    if (!cardElement) throw new Error("No Card Element");

    if (stripe && paymentIntent && paymentIntent.client_secret) {
      // Confirm Card Payment
      const {
        paymentIntent: updatedPaymentIntent,
        error,
      } = await stripe.confirmCardPayment(paymentIntent.client_secret, {
        payment_method: { card: cardElement },
      });

      if (error) {
        console.error(error);
        error.payment_intent && setPaymentIntent(error.payment_intent);
        setMessage("There was an error processing your payment.");
      } else {
        setPaymentIntent(updatedPaymentIntent);
        setMessage("Your payment was successfully processed. Thank you!");
      }
    }
  };

  return (
    <div className="BakeryInvoice">
      <header className="BakeryInvoiceHeader">
        <a href="https://lafloraison.store">
          <img src={logo} alt="La Floraison logo" />
        </a>
        <nav className="BakeryInvoiceNav">
          <ul>
            <li>
              <Link to="/Invoice">Custom Order</Link>
            </li>
            {false && (
              <li>
                <Link to="#">Pay Invoices</Link>
              </li>
            )}
          </ul>
        </nav>
      </header>
      <main className="BakeryInvoiceMain">
        <div className="BakeryInvoiceMainHeader">
          <h1>Pay for your invoice here</h1>
        </div>
        <form
          onSubmit={handleSubmit}
          className="well"
          hidden={!paymentIntent || paymentIntent.status === "succeeded"}
        >
          <CardElement
            options={{
              style: {
                base: {
                  fontWeight: 400,
                  fontFamily: "shippori_mincho",
                  fontSize: "32px",
                },
              },
            }}
          />
          <button className="btn btn-success" type="submit">
            Pay
          </button>
        </form>
        {message !== "" && (
          <div className="BakeryInvoiceMessage">{message}</div>
        )}
      </main>
      <footer className="BakeryInvoiceFooter">
        By <b>Ashley Muhm</b>
      </footer>
    </div>
  );
};
