import React, { useState } from "react";

import { Link } from "react-router-dom";
import { useFunctions } from "reactfire";

import logo from "../media/La_Floraison_Color.png";
import "./BakeryCustomOrder.css";

const getCategories = (cookie: boolean, cake: boolean, muffin: boolean) => {
  const categories = [];
  if (cookie) categories.push("COOKIE");
  if (cake) categories.push("CAKE");
  if (muffin) categories.push("MUFFIN");
  return categories;
};

export const CustomOrder = () => {
  const functions = useFunctions();
  const [message, setMessage] = useState(<></>);

  return (
    <div className="BakeryCustomOrder">
      <header className="BakeryCustomOrderHeader">
        <a href="https://lafloraison.store">
          <img src={logo} alt="La Floraison logo" />
        </a>
        <nav className="BakeryCustomOrderNav">
          <ul>
            <li>
              <Link to="/customOrder">Custom Order</Link>
            </li>
            {false && (
              <li>
                <Link to="#">Pay Invoices</Link>
              </li>
            )}
          </ul>
        </nav>
      </header>
      <main className="BakeryCustomOrderMain">
        <div className="BakeryCustomOrderMainHeader">
          <h1>Let us know what you would like, and we'll get back to you!</h1>
        </div>
        <form
          id="CustomOrderForm"
          onSubmit={(e: React.FormEvent<EventTarget>) => {
            e.preventDefault();
            setMessage(
              <div className="BakeryCustomOrderComplete">
                Your order is processing... Please wait.
              </div>
            );

            const formInput = e.target as HTMLFormElement;

            const data = {
              name: (formInput[0] as HTMLInputElement).value,
              email: (formInput[1] as HTMLInputElement).value,
              phone: (formInput[2] as HTMLInputElement).value,
              description: (formInput[3] as HTMLInputElement).value,
              categories: getCategories(
                (formInput[4] as HTMLInputElement).checked,
                (formInput[5] as HTMLInputElement).checked,
                (formInput[6] as HTMLInputElement).checked
              ),
              date: (formInput[7] as HTMLInputElement).value,
            };

            functions
              .httpsCallable("createOrder")(data)
              .then((res) => {
                setMessage(
                  <div className="BakeryCustomOrderComplete">
                    Your order has been submitted! We will get back to you ASAP!
                  </div>
                );

                (formInput[0] as HTMLInputElement).value = "";
                (formInput[1] as HTMLInputElement).value = "";
                (formInput[2] as HTMLInputElement).value = "";
                (formInput[3] as HTMLInputElement).value = "";
                (formInput[4] as HTMLInputElement).checked = false;
                (formInput[5] as HTMLInputElement).checked = false;
                (formInput[6] as HTMLInputElement).checked = false;
                (formInput[7] as HTMLInputElement).value = "";
              })
              .catch((err) => {
                setMessage(
                  <div className="BakeryCustomOrderComplete">
                    There was a problem submitting your custom order. Please
                    contact us at{" "}
                    <a href="mailto:help@lafloraison.store">
                      help@lafloraison.store
                    </a>
                    or try again later.
                  </div>
                );
              });
          }}
        >
          <label>
            Name
            <input id="name" type="text" />
          </label>
          <label>
            Email
            <input id="email" type="email" />
          </label>
          <label>
            Phone
            <input id="phone" type="tel" />
          </label>
          <label>
            Description
            <textarea id="description" form="CustomOrderForm" />
          </label>
          <div className="BakeryCustomOrderCategoriesLabel">Categories:</div>
          <div className="BakeryCustomOrderCategories">
            <label>
              Cookie
              <input id="cookie" type="checkbox" />
            </label>
            <label>
              Cake
              <input id="cake" type="checkbox" />
            </label>
            <label>
              Muffin
              <input id="muffin" type="checkbox" />
            </label>
          </div>
          <label>
            Date needed
            <input id="date" type="date" />
          </label>
          <button className="btn btn-success" type="submit">
            Submit Order
          </button>
        </form>
        {message !== <></> && message}
      </main>
      <footer className="BakeryCustomOrderFooter">
        By <b>Ashley Muhm</b>
      </footer>
    </div>
  );
};
