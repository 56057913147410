// External
import React from "react";
import "firebase/functions";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { FirebaseAppProvider } from "reactfire";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// Routes
import { Routes as AdminRoutes } from "./admin/routes";
import { Routes as BakeryRoutes } from "./bakery/routes";
import { Routes as HomemadeRoutes } from "./homemade/routes";

// Landing Page
import { LandingPage } from "./LandingPage";

const firebaseConfig = {
  apiKey: "AIzaSyAvSXjXIdF60J4e2b1FqdU_V4DztX41KiM",
  authDomain: "lafloraison-7f20e.firebaseapp.com",
  projectId: "lafloraison-7f20e",
  storageBucket: "lafloraison-7f20e.appspot.com",
  messagingSenderId: "884550517493",
  appId: "1:884550517493:web:b42617c36c4642707cbbbc",
  measurementId: "G-B9T4SFVRW7",
};

const stripePromise = loadStripe(
  "pk_live_51IFRi9Fox1rcA4iytJFVMKn2610EofYW4cyVAUs7Lc6n0inl6UCrE3A8xMlF8QOyJRlG5n9prVSIsvknJkn2d02i00aznRuPci"
);

function Router() {
  return (
    <BrowserRouter>
      <Switch>
        {window.location.host.split(".")[0] === "admin" && (
          <Route component={AdminRoutes} />
        )}
        {window.location.host.split(".")[0] === "bakery" && (
          <Route component={BakeryRoutes} />
        )}
        {window.location.host.split(".")[0] === "homemade" && (
          <Route component={HomemadeRoutes} />
        )}
        {["bakery", "homemade"].lastIndexOf(
          window.location.host.split(".")[0]
        ) === -1 && <LandingPage />}
      </Switch>
    </BrowserRouter>
  );
}

function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
      <Elements stripe={stripePromise}>
        <Router />
      </Elements>
    </FirebaseAppProvider>
  );
}

export default App;
